import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import Logo from "../images/deadtree.svg"
import TalkToUs from "./talkToUs"

const Container = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 0;
`

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={data => (
      <OuterContainer>
        <Container>
          <Logo width={"150px"} style={{ fill: "hsla(0, 0%, 0%, 0.8)" }} />
          <NameHeader>{data.site.siteMetadata.title}</NameHeader>
          <Description>{data.site.siteMetadata.subtitle}</Description>
        </Container>
        <section>
          <h2>What We Do</h2>
          <p>
            We build seamless apps and platforms tailored to your business. We
            can build from the ground up or integrate your existing systems
            (Oracle, Salesforce, SAP, Microsoft, ESRI) to deliver a crisp,
            transcendent experience. Sometimes we join existing projects to get
            them over the finish line.
          </p>
        </section>

        <TalkToUs>Talk To Us</TalkToUs>
        <section>
          <h2 id="team">Who We Are</h2>
          <p>
            Mulch was founded by{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/akoumjian/"
            >
              Alec Koumjian
            </a>
            , a product driven engineer with experience building boundless
            features and integrations.
          </p>
        </section>
      </OuterContainer>
    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default LandingBio
